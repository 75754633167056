@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  
  ol, ul, menu{
    list-style-type: unset;
    padding: unset ;
    margin: unset ;
    padding-inline-start: unset;
    margin-inline-end:unset;
    margin-inline-start:unset;
    margin-block-start:unset;
    margin-block-end:unset

  }
}

.simplebar-content .nav-item .nav-link {
  margin: 8px;
  padding: 10px;
  border-radius: 5px;
}

.simplebar-content .nav-item .nav-link:hover {
  background-color: #1c8dff;
}

.simplebar-content .nav-item .nav-link.active {
  background-color: #1c8dff;
}

.simplebar-content .nav-group .nav-link {
  margin: 8px;
  padding: 10px;
  border-radius: 5px;
}
.simplebar-content .nav-group .nav-link:hover {
  background-color: #1c8dff;
}

.ant-picker-ok .ant-btn{
  background-color: #1c8dff !important;
  color: #fff;
}

.htmlcontent ol{
  list-style-type: decimal;
}
.htmlcontent ul{
  list-style-type: disc;
}